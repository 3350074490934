import {getFromServer} from "./fetch";
import {JiraProject} from "./getJiraProjects";

interface SpaceConnectionsResponse {
    connections: SpaceConnection[];
}

export interface SpaceConnection {
    spaceAppClientId: string;
    spaceOrgHost: string;
    spaceOrgName?: string;
    spaceServerUrl: string;
    exposedJiraProjects: JiraProject[];
    exposeAllJiraProjects: boolean;
}

export async function loadSpaceConnections(): Promise<SpaceConnection[]> {
    let response: SpaceConnectionsResponse = await getFromServer(`/jira/api/space-connections`);
    return response.connections;
}
