import React, {FC, useState} from "react";
import {Button, ButtonKind} from "./Button";
import {RadioButtonOrientation, RadioButtons} from "./RadioButton";
import {JiraProject} from "../service/getJiraProjects";
import {ALL_PROJECTS, projectExposureChoiceButtons, SPECIFY_PROJECTS} from "./utils";
import {ProjectChooser} from "./projectChooser";
import {connectSpaceOrg} from "../service/connectSpaceOrg";

interface AddSpaceConnectionComponentProps {
    isJiraGlobalAdmin: Boolean;
    onDone: () => void;
}

export const AddSpaceConnectionComponent: FC<AddSpaceConnectionComponentProps> = ({isJiraGlobalAdmin, onDone}) => {
    const [allOrSpecificProjects, setAllOrSpecificProjects] = useState(isJiraGlobalAdmin ? ALL_PROJECTS : SPECIFY_PROJECTS);
    const [selectedProjects, setSelectedProjects] = useState([] as JiraProject[]);

    return <div className="flex flex-col items-center gap-4 text-center ">
        <span className="text-secondary-text-color">Select Jira projects to connect with Space</span>
        {
            !isJiraGlobalAdmin &&
            <span className="text-secondary-text-color text-sm">You need to have <i>Administer project</i> permission for the project in order to connect it</span>
        }

        {isJiraGlobalAdmin &&
            <RadioButtons
                orientation={RadioButtonOrientation.Horizontal}
                buttons={projectExposureChoiceButtons}
                selectedId={allOrSpecificProjects}
                onSelected={(selectedId: string) => {
                    setAllOrSpecificProjects(selectedId);
                }}
            />
        }

        {(allOrSpecificProjects == SPECIFY_PROJECTS) &&
            <ProjectChooser
                selectedProjects={selectedProjects}
                onSelectedProjectsChanged={(newProjects: JiraProject[]) => {
                    setSelectedProjects(newProjects);
                }}
            />
        }

        <Button kind={ButtonKind.Primary} text="Connect Space organization"
                disabled={allOrSpecificProjects == SPECIFY_PROJECTS && selectedProjects.length == 0}
                onClick={async () => {
                    await connectSpaceOrg(selectedProjects.map((project) => parseInt(project.id)));
                    onDone()
                }}
        />
    </div>
}
