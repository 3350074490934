import React, {FC, ReactEventHandler, ReactNode, useCallback} from "react";
import {SpaceConnection} from "../service/getSpaceConnections";

interface ConnectionNameProps {
    connection: SpaceConnection
}

export const ConnectionName: FC<ConnectionNameProps> = ({connection}) => {
    return <div className="flex flex-col items-start">
        <span className="text-lg">{connection.spaceOrgName}</span>
        <span className="text-sm text-secondary-text-color">{connection.spaceOrgHost}</span>
    </div>
}