import {SpaceConnection} from "../service/getSpaceConnections";
import React, {FC, useState} from "react";
import {Button, ButtonKind} from "./Button";
import {deleteSpaceConnection} from "../service/deleteSpaceConnection";

interface DeleteSpaceConnectionComponentProps {
    connection: SpaceConnection;
    onCancel: () => void;
    onDeleted: () => void;
}

enum DeletionState {
    Review,
    Deleting,
    Success,
    Failure
}

export const DeleteSpaceConnectionComponent: FC<DeleteSpaceConnectionComponentProps> = ({
                                                                                            connection,
                                                                                            onCancel,
                                                                                            onDeleted
                                                                                        }) => {
    return <div className="flex flex-col items-center gap-4 p-3 text-center">
        <span className="text-2xl font-semibold">Delete connection with {connection.spaceOrgName}</span>
        {
            connection.spaceOrgName == null
                ? <span>All data from this organization in Space<br/>will be removed from Jira</span>
                :
                <span>All data from {connection.spaceOrgName} organization<br/>in Space will be removed from Jira</span>

        }
        <div className="flex flex-row mt-5 justify-between w-full">
            <StatusAndSubmit connection={connection} onCancel={onCancel} onDeleted={onDeleted}/>
        </div>
    </div>
}


const StatusAndSubmit: FC<DeleteSpaceConnectionComponentProps> = ({connection, onCancel, onDeleted}) => {
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [deletionState, setDeletionState] = useState<DeletionState>(DeletionState.Review);
    const [resultMessage, setResultMessage] = useState("");

    switch (deletionState) {
        case DeletionState.Review:
            return <>
                <Button kind={ButtonKind.Secondary} text="Cancel" disabled={buttonsDisabled}
                        onClick={() => onCancel()}/>
                <Button kind={ButtonKind.Danger} text="Delete" disabled={buttonsDisabled}
                        onClick={async () => {
                            setButtonsDisabled(true);
                            setDeletionState(DeletionState.Deleting);
                            const result = await deleteSpaceConnection(connection.spaceAppClientId);
                            setResultMessage(result.message);
                            if (result.success) {
                                setDeletionState(DeletionState.Success);
                            } else {
                                setDeletionState(DeletionState.Failure);
                            }
                        }}/>

            </>
        case DeletionState.Deleting:
            return <>
                <DeletionStateComponent deletionState={deletionState} resultMessage={resultMessage}/>
                <Button kind={ButtonKind.Danger} text="Delete" disabled={buttonsDisabled}/>
            </>
        case DeletionState.Success:
            return <>
                <DeletionStateComponent deletionState={deletionState} resultMessage={resultMessage}/>
                <Button kind={ButtonKind.Secondary} text="Close" disabled={false} onClick={() => onDeleted()}/>
            </>
        case DeletionState.Failure:
            return <>
                <DeletionStateComponent deletionState={deletionState} resultMessage={resultMessage}/>
                <Button kind={ButtonKind.Secondary} text="Close" disabled={false} onClick={() => onDeleted()}/>
            </>
    }
}

interface DeletionStateComponentProps {
    deletionState: DeletionState;
    resultMessage: string;
}

const DeletionStateComponent = ({deletionState, resultMessage}: DeletionStateComponentProps): JSX.Element => {
    switch (deletionState) {
        case DeletionState.Review:
            return <></>
        case DeletionState.Deleting:
            return <span>Deleting...</span>
        case DeletionState.Success:
            return <span>Successfully deleted</span>
        case DeletionState.Failure:
            return <span>Could not delete: ${resultMessage}</span>
    }
}
