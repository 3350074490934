import {getFromServer} from "./fetch";

export interface CreateIssueRequest {
    summary: string | null;
    description: string | null;
    projectId: string | null;
    issueType: string | null;
    isValidRequest: boolean;
}

export async function getCreateIssueRequest(requestId: string): Promise<CreateIssueRequest> {
    return await getFromServer(`/jira/api/get-create-issue?requestId=${requestId}`);
}
