import {SpaceConnection} from "../service/getSpaceConnections";
import React, {FC, useState} from "react";
import {Button, ButtonKind} from "./Button";
import {ConnectionName} from "./connectionName";
import {RadioButtonOrientation, RadioButtons} from "./RadioButton";
import {JiraProject} from "../service/getJiraProjects";
import {ALL_PROJECTS, projectExposureChoiceButtons, SPECIFY_PROJECTS} from "./utils";
import {ProjectChooser} from "./projectChooser";
import {editSpaceConnection} from "../service/editSpaceConnection";

interface EditSpaceConnectionComponentProps {
    connection: SpaceConnection;
    onCancel: () => void;
    onSaved: (updatedConnection: SpaceConnection) => void;
}

export const EditSpaceConnectionComponent: FC<EditSpaceConnectionComponentProps> = (props) => {
    const [allOrSpecificProjects, setAllOrSpecificProjects] = useState(props.connection.exposeAllJiraProjects ? ALL_PROJECTS : SPECIFY_PROJECTS)
    const [selectedProjects, setSelectedProjects] = useState(props.connection.exposedJiraProjects);

    return <div className="flex flex-col items-center gap-4 text-center ">
        <ConnectionName connection={props.connection}/>

        <span className="text-secondary-text-color">Select Jira projects to connect with Space</span>

        <RadioButtons
            orientation={RadioButtonOrientation.Horizontal}
            buttons={projectExposureChoiceButtons}
            selectedId={allOrSpecificProjects}
            onSelected={(selectedId: string) => {
                setAllOrSpecificProjects(selectedId);
            }}
        />

        {
            allOrSpecificProjects == SPECIFY_PROJECTS
                ? <ProjectChooser
                    selectedProjects={selectedProjects}
                    onSelectedProjectsChanged={(newProjects: JiraProject[]) => {
                        setSelectedProjects(newProjects);
                    }}
                />
                : <></>
        }
        <div className="flex flex-row justify-between w-full">
            <Button className="self-end mt-5"
                    kind={ButtonKind.Secondary}
                    text="Cancel"
                    disabled={false}
                    onClick={() => props.onCancel()}/>
            <Button className="self-end mt-5" kind={ButtonKind.Primary} text="Save"
                    disabled={allOrSpecificProjects == SPECIFY_PROJECTS && selectedProjects.length == 0}
                    onClick={async () => {
                        const newSpaceConnection: SpaceConnection = {
                            spaceAppClientId: props.connection.spaceAppClientId,
                            spaceOrgHost: props.connection.spaceOrgHost,
                            spaceOrgName: props.connection.spaceOrgName,
                            spaceServerUrl: props.connection.spaceServerUrl,
                            exposedJiraProjects: selectedProjects,
                            exposeAllJiraProjects: allOrSpecificProjects == ALL_PROJECTS
                        }
                        props.onSaved(newSpaceConnection); // update UI optimistically
                        await editSpaceConnection(props.connection.spaceAppClientId, allOrSpecificProjects == ALL_PROJECTS, selectedProjects);
                    }}
            />
        </div>
    </div>
}
