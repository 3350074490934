import {postToServer} from "./fetch";

interface DeleteSpaceConnectionResponse {
    success: boolean;
    message: string;
}

export async function deleteSpaceConnection(spaceAppClientId: string): Promise<DeleteSpaceConnectionResponse> {
    try {
        return await postToServer(`/jira/api/delete-space-connection`, {
            spaceAppClientId: spaceAppClientId
        });
    } catch (e: any) {
        return {success: false, message: e.message};
    }
}
