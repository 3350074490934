import React, {useState} from "react";
import {SpaceConnections} from "./components/spaceConnections";

export const App = () => {
    const [isDarkTheme, setIsDarkTheme] = useState<boolean>(false);
    isDarkThemeSetter = setIsDarkTheme;

    return <>
        <div
            className="flex w-[600px] mr-40 ml-40 mb-40 mt-16 flex-col gap-6 items-center text-base font-medium font-sans">
            <SpaceConnections/>
        </div>
    </>
}

let isDarkThemeSetter: (value: (((prevState: boolean) => boolean) | boolean)) => void = () => {}

export enum Theme {
    LIGHT = "light",
    DARK = "dark"
}

function getTheme() {
    const htmlElement = document.getElementById('html-root') as HTMLHtmlElement;
    if (htmlElement.getAttribute('data-color-mode') === 'dark') {
        return Theme.DARK
    } else {
        return Theme.LIGHT
    }
}

export function themed(imageFilePath: string) {
    return getTheme() === Theme.DARK ? imageFilePath.replace('.svg', '-dark.svg') : imageFilePath;
}

document.addEventListener("DOMContentLoaded", () => {
    const htmlElement = document.getElementById('html-root') as HTMLHtmlElement;
    if (htmlElement.getAttribute('data-color-mode') === 'dark') {
        htmlElement.classList.add('dark');
    }

    // Jira sets "data-color-mode" attribute on the html element to indicate the current theme
    // observe the attribute and add/remove "dark" class to the html element
    // Tailwind applies dark theme based on the "dark" class (see tailwind.config.js)
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === 'data-color-mode') {
                const colorMode = htmlElement.getAttribute('data-color-mode');
                if (colorMode === 'dark') {
                    htmlElement.classList.add('dark');
                    isDarkThemeSetter(true);
                } else {
                    htmlElement.classList.remove('dark');
                    isDarkThemeSetter(false);
                }
            }
        });
    });
    observer.observe(htmlElement, {attributes: true});
});

export default App;
