import {postToServer} from "./fetch";
import {JiraProject} from "./getJiraProjects";

interface EditSpaceConnectionResponse {
    success: boolean;
    message: string;
}

export async function editSpaceConnection(spaceAppClientId: string, exposeAllJiraProjects: boolean, exposedJiraProjects: Array<JiraProject>): Promise<EditSpaceConnectionResponse> {
    try {
        return await postToServer(`/jira/api/edit-space-connection`, {
            spaceAppClientId: spaceAppClientId,
            exposeAllJiraProjects: exposeAllJiraProjects,
            exposedJiraProjects: exposedJiraProjects
        });
    } catch (e: any) {
        return {success: false, message: e.message};
    }
}
