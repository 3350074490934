import {postToServer} from "./fetch";

interface InitNewSpaceConnectionResponse {
    redirectUrl: string
}

/**
 * @param idsOfJiraProjectsToConnect empty array means all projects
 */
export async function connectSpaceOrg(idsOfJiraProjectsToConnect: number[]) {
    /**
     * On the server a unique id is generated for the process of connecting Space instance.
     */
    const response: InitNewSpaceConnectionResponse = await postToServer(`/jira/api/init-new-space-connection`, {
        idsOfProjectsToConnect: idsOfJiraProjectsToConnect
    });

    /**
     * The generated unique id is in the `state` parameter of the redirect url.
     * After the Space org is selected by the user and the Jira app is installed, the identifier
     * is sent to the app server by Space. The app server can thus tie the Space org to the Jira instance.
     **/
    window.open(response.redirectUrl)
}
