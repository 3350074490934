import {CreateIssueRequest, getCreateIssueRequest} from "./service/getCreateIssueRequest";
import {JiraProject} from "./service/getJiraProjects";
import {postCreatedIssues} from "./service/postCreatedIssues";

export default async function openCreateIssueDialog(requestId: string | null): Promise<JiraIssue[]> {
    let request = (requestId != null)
        ? await getCreateIssueRequest(requestId).catch((error) => {
            console.log(error);
            return null;
        })
        : null;

    let issues = await openCreateIssueDialogImpl(request);
    if (requestId != null && issues != null && issues.length > 0) {
        await postCreatedIssues(requestId, issues.map(issue => issue.key));
    }
    return issues;
}

export interface JiraIssue {
    id: string;
    key: string;
    self: string;   // url
    project: JiraProject;
    fields: object;
}

function openCreateIssueDialogImpl(request: CreateIssueRequest | null): Promise<JiraIssue[]> {
    return new Promise<JiraIssue[]>((resolve) => {
        AP.jira.openCreateIssueDialog(
            (issues: any[]) => {
                resolve(<JiraIssue[]>issues);
            },
            {
                pid: <any>request?.projectId,
                issueType: <any>request?.issueType,
                fields: {
                    summary: request?.summary ?? "",
                    description: request?.description ?? ""
                }
            }
        );
    });
}
