import React, {FC, useMemo, useState} from "react";
import {JiraProject} from "../service/getJiraProjects";

interface ProjectSelectProps {
    alreadySelectedProjects: JiraProject[];
    onProjectSelected: (selectedProject: JiraProject) => void;
    allProjects: JiraProject[];
}

export const ProjectSelect: FC<ProjectSelectProps> = ({alreadySelectedProjects, onProjectSelected, allProjects}) => {
    const [filter, setFilter] = useState("");

    const filteredProjects = useMemo(() => {
        const filterLower = filter.toLowerCase();
        return allProjects.filter((project) => {
            return (filterLower == "" || project.name.toLowerCase().includes(filterLower))
                && !alreadySelectedProjects.some((it) => it.key == project.key)
        });
    }, [filter, allProjects, alreadySelectedProjects]);

    const [inputFieldHasFocus, setInputFieldHasFocus] = useState(false);

    const selectPlaceholder = alreadySelectedProjects.length == 0 ? "Select project" : "Select another project";

    const chevronImg = inputFieldHasFocus
        ? "/images/chevron-up.svg"
        : "/images/chevron-down.svg";

    return <>
        <div className="relative w-full">
            <div
                className="rounded border-solid justify-between border-2 border-text-color/10 dark:border-secondary-text-color text-secondary-text-color focus:text-text-color p-3 flex flex-row cursor-text"
                onFocus={() => setInputFieldHasFocus(true)}
                onBlur={() => setInputFieldHasFocus(false)}
                onClick={() => document.getElementById("project-select-input")?.focus()}>

                <input id="project-select-input" placeholder={selectPlaceholder} type="text" value={filter}
                       className="border-0 outline-0 text-text-color dark:text-white bg-white dark:bg-background-dark cursor-text"
                       onInput={(event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value)}
                />
                <img src={chevronImg} alt=""/>
            </div>
            {
                inputFieldHasFocus
                    ? <div
                        className="absolute top-[100%] rounded border-2 border-solid border-text-color/10 dark:border-secondary-text-color w-full bg-white dark:bg-background-dark z-10 mt-2 left-0 max-h-96 overflow-y-scroll">
                        {
                            filteredProjects.length == 0
                                ? <div
                                    className="w-full p-3 rounded border-0 text-secondary-text-color hover:bg-text-color/10">
                                    No projects found
                                </div>
                                : filteredProjects.map((filteredProject) =>
                                    <div key={filteredProject.id}
                                         className="w-full p-3 rounded border-0 hover:bg-text-color/10 dark:hover:bg-secondary-text-color/30 cursor-pointer"
                                         onMouseDown={() => {
                                             onProjectSelected(filteredProject)
                                         }}
                                    >
                                        {filteredProject.name}
                                    </div>
                                )
                        }
                    </div>
                    : <></>
            }
        </div>
    </>
}
