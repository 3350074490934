import React, {FC, useEffect, useState} from "react";
import {getAllJiraProjects, JiraProject} from "../service/getJiraProjects";
import {ProjectSelect} from "./select";
import {isJiraGlobalAdmin} from "../service/jiraUserPermissions";

interface ProjectChooserProps {
    selectedProjects: JiraProject[];
    onSelectedProjectsChanged: (newProjects: JiraProject[]) => void;
}

export const ProjectChooser: FC<ProjectChooserProps> = ({selectedProjects, onSelectedProjectsChanged}) => {
    const [allProjects, setAllProjects] = useState<JiraProject[]>([]);

    useEffect(() => {
        let active = true
        fetchProjects();
        return () => {
            active = false
        }

        async function fetchProjects() {
            const projects = await getAllJiraProjects();
            if (!active) {
                return
            }
            setAllProjects(projects.filter(p => p.isAdmin));
        }
    }, []);


    return <>
        {
            allProjects.length > 0 &&
            <>
                <div className={"flex flex-row gap-4 text-secondary-text-color self-start"}>
                    <span className={"cursor-pointer"}
                        onClick={() => onSelectedProjectsChanged(allProjects)}>Add all projects</span>
                    {
                        selectedProjects.length > 0
                            ? <span
                                className={"cursor-pointer"}
                                onClick={() => onSelectedProjectsChanged([])}>Remove all added</span>
                            : <></>
                    }
                </div>
            </>
        }

        <div className={"w-full flex flex-col gap-4"}> {/*"max-h-96 overflow-y-scroll" to add the scrollbar*/}
            {
                selectedProjects.map((project, index) =>
                    <div key={index}
                         className="bg-main-color/10 p-3 text-main-color justify-between flex flex-row rounded w-full">
                        <span className="">{project.name}</span>
                        <img src="/images/cross.svg" className="cursor-pointer" alt=""
                             onClick={() => {
                                 const newProjects = selectedProjects.filter((it) => it.key != project.key)
                                 onSelectedProjectsChanged(newProjects)
                             }}/>
                    </div>)
            }
        </div>
        <ProjectSelect
            alreadySelectedProjects={selectedProjects}
            onProjectSelected={(selectedProject) => {
                const newProjects = selectedProjects.concat([selectedProject])
                onSelectedProjectsChanged(newProjects);
            }}
            allProjects={allProjects}
        />
    </>
}
