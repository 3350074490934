import React, {FC, useEffect, useState} from "react";
import {loadSpaceConnections, SpaceConnection} from "../service/getSpaceConnections";
import {connectSpaceOrg} from "../service/connectSpaceOrg";
import {Loader} from "./loader";
import {JiraProject} from "../service/getJiraProjects";
import {RadioButtonOrientation, RadioButtons} from "./RadioButton";
import {Button, ButtonKind} from "./Button";
import {ProjectChooser} from "./projectChooser";
import {SpaceConnectionComponent} from "./spaceConnection";
import {ALL_PROJECTS, projectExposureChoiceButtons, SPECIFY_PROJECTS} from "./utils";
import {AddSpaceConnectionComponent} from "./addSpaceConnectionComponent";
import {DialogComponent} from "./dialogComponent";
import {themed} from "../App";
import {isJiraGlobalAdmin as getisJiraGlobalAdmin} from "../service/jiraUserPermissions";

export const SpaceConnections: FC = () => {
    const [spaceConnections, setSpaceConnections] = useState<SpaceConnection[] | undefined>(undefined);
    const [isJiraGlobalAdmin, setisJiraGlobalAdmin] = useState(false);

    useEffect(() => {
        loadSpaceConnections().then(result => setSpaceConnections(result)).catch(console.error);
        getisJiraGlobalAdmin().then(result => setisJiraGlobalAdmin(result)).catch(console.error);
    }, []);

    return <>
        {
            spaceConnections === undefined
                ? <Loader/>
                : <ConnectionList connections={spaceConnections} isJiraGlobalAdmin={isJiraGlobalAdmin}
                                  onConnectionDeleted={(deletedConnection: SpaceConnection) => {
                                      const newConnections = spaceConnections.filter((it) => it.spaceAppClientId != deletedConnection.spaceAppClientId);
                                      setSpaceConnections(newConnections);
                                  }}
                                  onConnectionUpdated={(updatedConnection: SpaceConnection) => {
                                      const newConnections = spaceConnections.map((it) => it.spaceAppClientId === updatedConnection.spaceAppClientId ? updatedConnection : it);
                                      setSpaceConnections(newConnections);
                                  }}
                />
        }
    </>
}

interface SpaceConnectionListProps {
    connections: SpaceConnection[];
    isJiraGlobalAdmin: boolean;
    onConnectionDeleted: (deletedConnection: SpaceConnection) => void;
    onConnectionUpdated: (updatedConnection: SpaceConnection) => void;
}

const ConnectionList: FC<SpaceConnectionListProps> = (props) => {
    const [addSpaceConnectionDialogOpen, setAddSpaceConnectionDialogOpen] = useState(false);

    return <>
        <img src={themed("/images/connect-your-space.svg")} alt=""/>
        <span className="text-3xl font-semibold">Connect your Space</span>
        <span className="text-center">Improve communication throughout the software development<br/>lifecycle by bringing your Jira issues into Space.</span>
        {
            props.connections && props.connections.length
                ? <>
                    <div className="flex flex-col gap-3 w-full">
                        <span className="text-center">Connected Space Organizations</span>

                        {
                            props.connections.map((connection, index) =>
                                <SpaceConnectionComponent key={index}
                                                          connection={connection}
                                                          isJiraGlobalAdmin={props.isJiraGlobalAdmin}
                                                          onConnectionDeleted={props.onConnectionDeleted}
                                                          onConnectionUpdated={props.onConnectionUpdated}
                                />
                            )
                        }
                    </div>

                    {props.isJiraGlobalAdmin &&
                        <>
                            <Button kind={ButtonKind.Secondary}
                                    text="Add another Space connection"
                                    disabled={false}
                                    onClick={async () => {
                                        setAddSpaceConnectionDialogOpen(true)
                                    }}/>

                            <DialogComponent isOpen={addSpaceConnectionDialogOpen}
                                             onClose={() => setAddSpaceConnectionDialogOpen(false)}>
                                <AddSpaceConnectionComponent
                                    isJiraGlobalAdmin={props.isJiraGlobalAdmin}
                                    onDone={() => setAddSpaceConnectionDialogOpen(false)}
                                />
                            </DialogComponent>
                        </>
                    }
                </>
                : <ConnectSpaceEmptyState isJiraGlobalAdmin={props.isJiraGlobalAdmin} />
        }
    </>
}

interface ConnectSpaceEmptyStateProps {
    isJiraGlobalAdmin: boolean
}

const ConnectSpaceEmptyState: FC<ConnectSpaceEmptyStateProps> = ({ isJiraGlobalAdmin }) => {
    const [allOrSpecificProjects, setAllOrSpecificProjects] = useState(SPECIFY_PROJECTS)

    const [selectedProjects, setSelectedProjects] = useState([] as JiraProject[]);

    return isJiraGlobalAdmin
        ? <>
            <div className="flex flex-col">
                <span className="text-center">Choose which Jira projects you want to connect with Space</span>
            </div>

            <div className="w-7/12 gap-2 flex flex-col">
                <RadioButtons
                    orientation={RadioButtonOrientation.Vertical}
                    buttons={projectExposureChoiceButtons}
                    selectedId={allOrSpecificProjects}
                    onSelected={(selectedId: string) => {
                        setAllOrSpecificProjects(selectedId);
                    }}
                />

                {
                    allOrSpecificProjects == SPECIFY_PROJECTS
                        ? <ProjectChooser
                            selectedProjects={selectedProjects}
                            onSelectedProjectsChanged={(newProjects: JiraProject[]) => {
                                setSelectedProjects(newProjects);
                            }}
                        />
                        : <></>
                }

                <Button kind={ButtonKind.Primary} text="Connect"
                        disabled={allOrSpecificProjects === "" || allOrSpecificProjects == SPECIFY_PROJECTS && selectedProjects.length == 0}
                        onClick={async () => {
                            if (allOrSpecificProjects == ALL_PROJECTS) {
                                await connectSpaceOrg([]);
                            } else if (allOrSpecificProjects == SPECIFY_PROJECTS) {
                                const projectIds = selectedProjects.map((project) => parseInt(project.id));
                                await connectSpaceOrg(projectIds);
                            }
                        }}/>
            </div>
        </>
        : <>
            <span className="text-center text-secondary-text-color">No Space organizations connected.</span>
            <span className="text-center text-secondary-text-color text-sm">You need to have <i>Administer Jira</i> permission to connect Space organization to Jira.</span>
        </>;
}
