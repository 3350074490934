import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app/App';
import CreateIssueApp from "./app/CreateIssueApp";

AP.getLocation((location: string) => {
    let rootElement = document.getElementById("root") as HTMLElement;
    const root = ReactDOM.createRoot(rootElement);
    let url = new URL(location);
    switch (url.pathname.substring(url.pathname.lastIndexOf('/'))) {
        case '/space-addon-create-issue': {
            root.render(
                <CreateIssueApp url={url} />
            );
            break;
        }

        default: {
            root.render(
                <App/>
            );
        }
    }
});
