import React, {FC} from "react";

export enum ButtonKind {
    Primary,
    Secondary,
    Danger,
}

interface ButtonProps {
    kind: ButtonKind;
    text: string;
    disabled: boolean;
    onClick?: () => void;
    className?: string;
}

export const Button: FC<ButtonProps> = ({kind, text, disabled, onClick, className }) => {
    const disabledEnabled = disabled ? "bg-text-color/50 dark:bg-text-color opacity-50 cursor-default" : getEnabledStyle(kind)

    return <button className={`font-sans py-2 px-4 rounded outline-0 ${disabledEnabled} ${className ? className : ""}`}
                   onClick={() => {
                       if (!disabled) {
                           onClick && onClick()
                       }
                   }}>
        {text}
    </button>
}

function getBaseTextColor(kind: ButtonKind): string {
    switch (kind) {
        case ButtonKind.Primary:
            return "text-white";
        case ButtonKind.Secondary:
            return "text-main-color";
        case ButtonKind.Danger:
            return "text-error-color";
    }
}

function getEnabledStyle(kind: ButtonKind): string {
    switch (kind) {
        case ButtonKind.Primary:
            return "transition ease-in-out text-white bg-main-color hover:bg-accent-color active:bg-main-color";
        case ButtonKind.Secondary:
            return "transition ease-in-out text-main-color border border-main-color bg-transparent hover:bg-main-color/10 active:text-white active:bg-main-color";
        case ButtonKind.Danger:
            return "transition ease-in-out text-error-color border border-error-color bg-transparent hover:bg-error-color/10 active:text-white active:bg-error-color";
    }
}