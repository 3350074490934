import React, {FC, useEffect, useRef, useState} from "react";
import {SpaceConnection} from "../service/getSpaceConnections";
import {DialogComponent} from "./dialogComponent";
import {DeleteSpaceConnectionComponent} from "./deleteSpaceConnectionComponent";
import {JiraProject} from "../service/getJiraProjects";
import {EditSpaceConnectionComponent} from "./editSpaceConnectionComponent";
import {ConnectionName} from "./connectionName";

interface SpaceConnectionProps {
    connection: SpaceConnection;
    isJiraGlobalAdmin: boolean;
    onConnectionDeleted: (deletedConnection: SpaceConnection) => void;
    onConnectionUpdated: (updatedConnection: SpaceConnection) => void;
}

export const SpaceConnectionComponent: FC<SpaceConnectionProps> = (props) => {
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    return <div className="w-full rounded border-solid border-2 border-text-color/10 dark:border-secondary-text-color/30 flex flex-row p-3 gap-4">
        <ConnectionName connection={props.connection}/>

        {/* spring */}
        <div className="flex flex-row min-w-0 grow-[10] basis-[1px]"/>

        <JiraProjectsSummary jiraProjects={props.connection.exposedJiraProjects}/>

        {
            props.isJiraGlobalAdmin &&

            <div className="flex flex-row gap-2">
                <img className="cursor-pointer" src="/images/edit.svg" alt=""
                     onClick={() => {
                         setEditDialogOpen(true);
                     }}/>
                <DialogComponent isOpen={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
                    <EditSpaceConnectionComponent
                        connection={props.connection}
                        onCancel={() => setEditDialogOpen(false)}
                        onSaved={(updatedConnection) => {
                            setEditDialogOpen(false);
                            props.onConnectionUpdated(updatedConnection);
                        }}
                    />
                </DialogComponent>

                <img className="cursor-pointer" src="/images/trash.svg" alt=""
                     onClick={() => {
                         setDeleteDialogOpen(true);
                     }}/>
                <DialogComponent isOpen={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
                    <DeleteSpaceConnectionComponent
                        connection={props.connection}
                        onCancel={() => setDeleteDialogOpen(false)}
                        onDeleted={() => {
                            setDeleteDialogOpen(false);
                            props.onConnectionDeleted(props.connection);
                        }}
                    />
                </DialogComponent>
            </div>
        }
    </div>
}

interface JiraProjectsSummaryProps {
    jiraProjects: JiraProject[];
}

const JiraProjectsSummary: FC<JiraProjectsSummaryProps> = ({jiraProjects}) => {
    const [isProjectListShown, setIsProjectListShown] = useState(false);

    const wrapperRef = useRef<HTMLInputElement>(null);
    useOutsideAlerter(wrapperRef, () => setIsProjectListShown(false));

    const chevronImg = isProjectListShown
        ? "/images/chevron-up-main-color.svg"
        : "/images/chevron-down-main-color.svg";

    const cursorClass = jiraProjects.length > 0 ? "cursor-pointer" : "";

    return <div ref={wrapperRef}
                className={`relative flex flex-row mr-3 items-center gap-1 ${cursorClass}`}
                onClick={() => setIsProjectListShown(!isProjectListShown)}
                onBlur={() => setIsProjectListShown(false)}
    >
            <span className="text-main-color whitespace-nowrap">
                {jiraProjects.length} Jira projects
            </span>
        {
            jiraProjects.length > 0
                ? <>
                    <img src={chevronImg} alt=""/>
                    {
                        // popup with list of projects
                        isProjectListShown
                            ? <div
                                className="absolute top-[100%] right-0 rounded border-solid border border-text-color/10 flex flex-col gap-2 p-3 bg-white dark:bg-background-dark z-10 text-main-color drop-shadow-xl cursor-default"
                                onClick={(event) => event.stopPropagation()}
                            >
                                {
                                    jiraProjects.map((project, i) =>
                                            <span
                                                key={project.key}
                                                className="whitespace-nowrap"
                                            >
                                    {project.name}
                                </span>
                                    )
                                }
                            </div>
                            : <></>
                    }

                </>
                : <></>
        }

    </div>
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: React.RefObject<HTMLElement>, onClickedOutside: () => void) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as HTMLElement)) {
                onClickedOutside();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
}
