import {Loader} from "./components/loader";
import {useEffect, useState} from "react";
import openCreateIssueDialog, {JiraIssue} from "./openCreateIssueDialog";

export const CreateIssueApp = (props: { url: URL }) => {
    const [outcome, setOutcome] = useState<String | null>();

    useEffect(() => {
        if (outcome == null) {
            let requestId = props.url.searchParams.get('requestId');
            openCreateIssueDialog(requestId)
                .then((issues) => {
                    if (issues.length > 0) {
                        AP.navigator.go('issue', {issueKey: issues[issues.length - 1].key});
                    } else {
                        setOutcome("You have cancelled the issue creation.");
                    }
                })
                .catch((error) => {
                    setOutcome(`Something went wrong while creating an issue. ${error}`);
                });
        }
    });

    return <>
        <div className="flex m-40 flex-col gap-6 items-center">
            {outcome != null
                ? <div className="text-2xl">{outcome}</div>
                : <Loader/>}
        </div>
    </>
}

export default CreateIssueApp;