export async function getFromServer<T>(path: string): Promise<T> {
    const rawResponse = await fetch(path, {method: 'GET'});
    if (!rawResponse.ok) {
        throw new Error(`Got ${rawResponse.status} ${rawResponse.statusText} from server. Response is\n${await rawResponse.text()}`);
    }
    return await rawResponse.json();
}

export async function postToServer<T>(path: string, bodyObject: object): Promise<T> {
    const rawResponse = await fetch(path,
        {
            method: 'POST',
            body: JSON.stringify(bodyObject)
        });
    if (!rawResponse.ok) {
        throw new Error(`got ${rawResponse.status}: ${rawResponse.statusText} from server`);
    }
    return await rawResponse.json();
}
