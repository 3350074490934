import {FC, MouseEvent, ReactNode, useCallback, useEffect, useRef} from "react";

interface DeleteSpaceConnectionDialogProps {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
}

export const DialogComponent: FC<DeleteSpaceConnectionDialogProps> = ({isOpen, onClose, children}) => {
    const onClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
        const {current: el} = modalRef;
        if (e.target === el && el) {
            onClose();
        }
    }, []);

    const onKeyDown = ({key}: KeyboardEvent) => {
        if (key === "Escape") {
            onClose();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);
        return () => {
            window.removeEventListener("keydown", onKeyDown);
        }
    });

    const modalRef = useRef<HTMLDivElement>(null);

    return <>
        {
            isOpen
                ? <div ref={modalRef}
                       className="absolute top-0 left-0 w-full h-full bg-text-color/30 dark:bg-text-color/50 flex flex-col items-center z-10 pt-[20%]"
                       onClick={onClick}
                >
                    <div className="relative w-[480px] bg-white dark:bg-background-dark opacity-100 rounded drop-shadow-xl px-16 pt-6 pb-8">
                        {children}
                    </div>
                </div>

                : <></>
        }
    </>
}
