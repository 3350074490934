export async function isJiraGlobalAdmin(): Promise<boolean> {
    let { body } = await AP.request({
        url: '/rest/api/3/mypermissions?permissions=ADMINISTER',
        error: function () { console.log(arguments); }
    });
    return JSON.parse(body).permissions.ADMINISTER.havePermission;
}

export async function getProjectIdsWithAdminPermission(): Promise<Set<string>> {
    let { body} = await AP.request({
        url: '/rest/api/3/permissions/project',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ permissions: ["ADMINISTER_PROJECTS"] }),
        error: function () { console.log(arguments); }
    });
    let permissions: ProjectPermissionsResponse = JSON.parse(body);
    return new Set(permissions.projects.map(project => project.id.toString()));
}

interface ProjectPermissionsResponse {
    projects: ProjectPermissionsResponseItem[];
}

interface ProjectPermissionsResponseItem {
    id: number;
    key: string;
}