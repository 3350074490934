import {getProjectIdsWithAdminPermission} from "./jiraUserPermissions";

export interface JiraProject {
    id: string;
    key: string;
    name: string;
    isAdmin: boolean;
}

export async function getAllJiraProjects(): Promise<JiraProject[]> {
    let {body} = await AP.request({
        url: `/rest/api/3/project`,
        error: function () { console.log(arguments); }
    });
    let projects: JiraProject[] = JSON.parse(body);

    let canAdminProjectIds = await getProjectIdsWithAdminPermission();
    projects.forEach(p => { p.isAdmin = canAdminProjectIds.has(p.id) });

    return projects;
}
