import React, {FC} from "react";
import {themed} from "../App";

export enum RadioButtonOrientation {
    Horizontal,
    Vertical
}

interface RadioButtonInfo {
    id: string;
    text: string;
    warning?: string;
}

interface RadioButtonsProps {
    buttons: RadioButtonInfo[];
    selectedId?: string;
    onSelected: (selectedId: string) => void;
    orientation: RadioButtonOrientation;
}

export const RadioButtons: FC<RadioButtonsProps> = ({buttons, selectedId, onSelected, orientation}) => {
    const selectedStyle = "border-main-color bg-main-color/5"
    const notSelectedStyle = "border-text-color/10 dark:border-secondary-text-color/30"

    const buttonWithWarning = buttons.filter((button) => button.warning)[0]

    {
        return orientation == RadioButtonOrientation.Vertical
            ? <div className="flex flex-col gap-2">
                {
                    buttons.map((button: RadioButtonInfo, i) => {
                        const isSelected = button.id == selectedId
                        const style = isSelected ? selectedStyle : notSelectedStyle;
                        const imgFileName = isSelected ? "/images/radio-button-selected.svg" : themed("/images/radio-button-not-selected.svg");
                        return <>
                            <div
                                className={`border-solid border-2 ${style} rounded p-3 flex flex-row gap-3 cursor-pointer`}
                                key={i} onClick={() => onSelected(button.id)}>

                                <img src={imgFileName} alt=""/>
                                <span>{button.text}</span>
                            </div>
                            {
                                isSelected && button.warning
                                    ? <div
                                        className={`border-none rounded bg-secondary-text-color/10 text-secondary-text-color text-sm font-bold p-3 flex flex-row gap-3`}
                                        key={-1}>

                                        <img className="self-start" src={themed("/images/warning-gray.svg")} alt=""/>
                                        <span>{button.warning}</span>
                                    </div>
                                    : <></>
                            }
                        </>
                    })
                }
            </div>
            : <>
                <div className="flex flex-row gap-4">
                    {
                        buttons.map((button: RadioButtonInfo, i) => {
                            const isSelected = button.id == selectedId
                            const imgFileName = isSelected ? "/images/radio-button-selected.svg" : themed("/images/radio-button-not-selected.svg");
                            return <div
                                className={`flex flex-row gap-2 cursor-pointer`}
                                key={i} onClick={() => onSelected(button.id)}>

                                <img src={imgFileName} alt=""/>
                                <span>{button.text}</span>
                            </div>
                        })
                    }
                </div>
                {
                    buttonWithWarning && buttonWithWarning.id == selectedId
                        ? <div
                            className={`border-none rounded bg-secondary-text-color/10 text-left text-secondary-text-color text-sm font-bold p-3 flex flex-row gap-3`}
                        >
                            <img className="self-start" src={themed("/images/warning-gray.svg")} alt=""/>
                            <span>{buttonWithWarning.warning}</span>
                        </div>
                        : <></>
                }
            </>
    }
}
